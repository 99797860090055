
// action - state management
import { MY_BANK_DETAILS,CREDIT_DASHBOARD_LIST,FINANCIAL_DASHBOARD_LIST,WITHDRAWAL_LIST } from "./actions";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    creditDashboardList: {
        docs: [],
        totalDocs: 0,
        limit: 10,
        page: 1,
        totalPages: 0,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: null,
        nextPage: null,
      },
      financialDashboardList: {
        docs: [],
        totalDocs: 0,
        limit: 10,
        page: 1,
        totalPages: 0,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: null,
        nextPage: null,
      },
      withdrawalList: {
        docs: [],
        totalDocs: 0,
        limit: 10,
        page: 1,
        totalPages: 0,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: null,
        nextPage: null,
      },
  myBankDetails: {},
  loading: true,
};

export default function myProfileReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case MY_BANK_DETAILS:
      return {
        ...state,

        myBankDetails: payload,
        loading: false,
      };
      case CREDIT_DASHBOARD_LIST:
        return {
          ...state,
  
          creditDashboardList: payload,
          loading: false,
        };
        case FINANCIAL_DASHBOARD_LIST:
          return {
            ...state,
    
            financialDashboardList: payload,
            loading: false,
          };
          case WITHDRAWAL_LIST:
            return {
              ...state,
      
              withdrawalList: payload,
              loading: false,
            };
    default:
      return state;
  }
}