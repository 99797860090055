import axios from "axios";
import { ENROLLMENT_COURSE_LIST, ENROLLMENT_COURSE_DETAILS } from "../actions";

const PROXY = process.env.REACT_APP_URL + "api/";

export const getEnrollmentCourseList =
  (page, limit, search, categoryId) => async (dispatch) => {
    try {
      const res = await axios.get(
        PROXY +
          `user/course/enrolled-course?page=${page}&limit=${limit}&search=${search}&category=${categoryId}`
      );

      dispatch({
        type: ENROLLMENT_COURSE_LIST,
        payload: res.data.result,
      });
      return res;
    } catch (error) {}
  };

  export const getEnrollmentCourseDetails=(id) => async (dispatch) => {
    try {
      const res = await axios.get(PROXY + `user/course/enrolled-course-details/${id}`);
  
      dispatch({
        type: ENROLLMENT_COURSE_DETAILS,
        payload: res.data.result,
      });
      return res.data.result;
    } catch (error) {}
  };

  export const displaySuccessCoursePurchase = (data) => async (dispatch) => {
    try {
    const res= await axios.post(PROXY + "user/course/payment-check", data);
    return res;
    } catch (error) {
      return error.response;
    }
  };