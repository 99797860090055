// action - state management
import {
  DASHBOARD_CATEGORY,
  TOP_COURSE_LIST,
  SELECTED_CATEGORY,
  TOP_COURSE_DETAILS,
  RATING_LIST,DASHBOARD_DATA,SELECTED_INSTRUCTOR_CATEGORY,MY_COURSE_DETAILS,MY_COURSE__EDIT_DETAILS
} from "./actions";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  categoryList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  topCourseList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  ratingsList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  selectedCategories: [],
  selectedInstructorCategories:[],
  topCourseDetails: {},
  myCourseDetails:{},
  myCourseEditDetails:{},
  courseDetails:{},
  dashboardData:{},
  loading: true,
};

export default function dashboardReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DASHBOARD_CATEGORY:
      return {
        ...state,

        categoryList: payload,
        loading: false,
      };
    case DASHBOARD_DATA:
      return {
        ...state,

        dashboardData: payload,
        loading: false,
      };
    case TOP_COURSE_LIST:
      return {
        ...state,

        topCourseList: payload,
        loading: false,
      };
    case SELECTED_CATEGORY:
      return {
        ...state,

        selectedCategories: payload,
      };
    case SELECTED_INSTRUCTOR_CATEGORY:
      return{
        ...state,
        selectedInstructorCategories:payload
      }
    case TOP_COURSE_DETAILS:
      return {
        ...state,

        topCourseDetails: payload,
      };
    case MY_COURSE_DETAILS:
      return {
        ...state,

        myCourseDetails: payload,
      }; 
    case MY_COURSE__EDIT_DETAILS:
      return {
        ...state,

        myCourseEditDetails: payload,
      }; 
    case RATING_LIST:
      return {
        ...state,

        ratingsList: payload,
        loading: false,
      };
    default:
      return state;
  }
}
