// action - state management
import {
    GET_CMS_CONTENT,

  } from "./actions";
  
  // ==============================|| ACCOUNT REDUCER ||============================== //
  
  const initialState = {
    cms: null,
    loading: true,
  };
  
  export default function cmsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_CMS_CONTENT:
        return {
          ...state,
  
          cms: payload,
          loading: false,
        };
 

      default:
        return state;
    }
  }