import { lazy } from "react";
import { useRoutes } from "react-router-dom";

// routes
import AuthenticationRoutes from "./AuthenticationRoutes";
import Loadable from "ui-component/Loadable";
import MainRoutes from "./MainRoutes";
import GuestRoutes from "./GuestRoutes";

// import MinimalLayout from 'layout/MinimalLayout';
const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    { path: "/", element: <PagesLanding /> },
    AuthenticationRoutes,
    GuestRoutes,
    MainRoutes,
  ]);
}
