

// action - state management
import { ENROLLMENT_COURSE_LIST, SELECTED_ENROLLMENT_COURSE_CATEGORY,ENROLLMENT_COURSE_DETAILS } from "./actions";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  enrollmentCourseList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  selectedEnrollmentCourseCategories: [],
  enrollmentCourseDetails: {},
  loading: true,
};

export default function enrollmentCourseReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ENROLLMENT_COURSE_LIST:
      return {
        ...state,

        enrollmentCourseList: payload,
        loading: false,
      };
    case SELECTED_ENROLLMENT_COURSE_CATEGORY:
      return {
        ...state,

        selectedEnrollmentCourseCategories: payload,
        loading: false,
      };
    case ENROLLMENT_COURSE_DETAILS:
      return {
        ...state,

        enrollmentCourseDetails: payload,
        loading: false,
      }; 
    default:
      return state;
  }
}