import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

const Enrollment = Loadable(
  lazy(() => import("views/pages/Enrollments/Enrollments"))
);
const EnrollmentDetails = Loadable(
  lazy(() => import("views/pages/Enrollments/EnrollmentCourseDetails"))
);
const EnrollmentRatings = Loadable(
  lazy(() => import("views/pages/Enrollments/EnrollmentRating"))
);
const SelectCategoryEnrollmentCourse = Loadable(
  lazy(() => import("views/pages/Enrollments/SelectCategory"))
);


// const AllCourses = Loadable(
//   lazy(() => import("views/pages/AllCourses/AllCourses"))
// );
// const SelectCategory = Loadable(
//   lazy(() => import("views/pages/SelectCategory/SelectCategory"))
// );


const PublicCourse = Loadable(
  lazy(() => import("views/pages/PublicCourse/PublicCourse"))
);

const PublicCourseMainPage = Loadable(
  lazy(() => import("views/pages/PublicCourse/PublicCourseMainPage"))
);

const MyCourses = Loadable(lazy(() => import("views/pages/MyCourse/MyCourse")));
const MyCourseDetail = Loadable(
  lazy(() => import("views/pages/MyCourse/MyCourseDetails"))
);
const MyDraftCourseDetail = Loadable(
  lazy(() => import("views/pages/MyCourse/MyDraftCourseDetails"))
);
const MyCourseRatings = Loadable(
  lazy(() => import("views/pages/MyCourse/CourseRatings"))
);
const EditCourse = Loadable(
  lazy(() => import("views/pages/MyCourse/EditCourse"))
);
const SelectCategoryMyCourse = Loadable(
  lazy(() => import("views/pages/MyCourse/SelectCategory"))
);
const ExploreCourse = Loadable(
  lazy(() => import("views/pages/Enrollments/ExploreCourse"))
);

const MyProfile = Loadable(
  lazy(() => import("views/pages/MyProfile/MyProfile"))
);
const EditProfile = Loadable(
  lazy(() => import("views/pages/MyProfile/EditProfile"))
);
const CreditDashboard = Loadable(
  lazy(() => import("views/pages/MyProfile/CreditDashboard"))
);
const FinancialDashboard = Loadable(
  lazy(() =>
    import("views/pages/MyProfile/FinancialDashboard/FinancialDashboard")
  )
);
const Withdrawal = Loadable(
  lazy(() => import("views/pages/MyProfile/Withdrawal/Withdrawal"))
);
const Settings = Loadable(lazy(() => import("views/pages/Settings/Settings")));




const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/enrolments",
      element: <Enrollment />,
    },
    {
      path: "/enrolment-details/:id",
      element: <EnrollmentDetails />,
    },
    {
      path: `/enrolments/:selectedpage`,
      element: <Enrollment />,
    },
    {
      path: "/enrolment-details/:id/:selectedpage",
      element: <EnrollmentDetails />,
    },
    {
      path: "/enrolment-ratings/:id",
      element: <EnrollmentRatings />,
    },
    {
      path:"/enrolment-select-category",
      element:<SelectCategoryEnrollmentCourse/>
    },

  
  
    // {
    //   path: "/all-courses",
    //   element: <AllCourses />,
    // },
    // {
    //   path: "/select-category",
    //   element: <SelectCategory />,
    // },


    {
      path: "/public-course",
      element: <PublicCourse />,
    },
    {
      path: "/public-course-form",
      element: <PublicCourseMainPage />,
    },
    {
      path: "/my-course",
      element: <MyCourses />,
    },
    {
      path: "/my-course/:selectedpage",
      element: <MyCourses />,
    },
    {
      path: "/my-course-details/:id",
      element: <MyCourseDetail />,
    },
    {
      path: "/my-course-details/:id/:selectedpage",
      element: <MyCourseDetail />,
    },
    {
      path: "/my-draft-course-details/:id/:selectedpage",
      element: <MyDraftCourseDetail />,
    },
    {
      path: "/my-course-ratings/:id",
      element: <MyCourseRatings />,
    },
    {
      path: "/edit-course/:id",
      element: <EditCourse />,
    },
    {
      path: "/select-category-my-course",
      element: <SelectCategoryMyCourse />,
    },
    {
      path: "/explore-course",
      element: <ExploreCourse />,
    },
    {
      path: "/my-profile",
      element: <MyProfile />,
    },
    {
      path: "/edit-profile",
      element: <EditProfile />,
    },
    {
      path: "/credit-dashboard",
      element: <CreditDashboard />,
    },
    {
      path: "/financial-dashboard",
      element: <FinancialDashboard />,
    },
    {
      path: "/withdrawal",
      element: <Withdrawal />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
  
  ],
};
export default MainRoutes;
