import React,{useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { Grid, CircularProgress, DialogContent, Typography } from "@mui/material";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import LogoutIcon from "assets/images/Profile/logout-svgrepo-com.svg";

import { connect } from "react-redux";
import { logout } from "store/slices/userLoginAction";

const LogoutModal = ({ open, close, scroll, logout,label }) => {
  const { hallLabelsName } = label;
  const navigate = useNavigate();
  const[loader,setLoader]=useState(false)
  return (
    <>
      <div>
        <Dialog
          disableScrollLock={true}
          open={open}
          onClose={close}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          PaperProps={{ sx: { borderRadius: "13px", width: "390px" } }}
          sx={{ padding: 0 }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              pt: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={LogoutIcon} alt="" />
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                width: "176px",
                color: "#252525",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
               { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_are_you_sure_you_want_to_logout_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_are_you_sure_you_want_to_logout_web"
              )[0]["value"] || "lbl_are_you_sure_you_want_to_logout_web"
            : "lbl_are_you_sure_you_want_to_logout_web"
          : "lbl_are_you_sure_you_want_to_logout_web"}
              
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              pt: 0.5,
              display: "flex",

              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                border: "1px solid #9D9D9D",
                width: "127px",
                height: "48px",
                borderRadius: "24px",
                fontWeight: 500,
                color: "#9D9D9D",
                "&:hover": {
                  border: "1px solid #9D9D9D",
                },
              }}
              onClick={close}
            >
               { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_cancel_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_cancel_web"
              )[0]["value"] || "lbl_cancel_web"
            : "lbl_cancel_web"
          : "lbl_cancel_web"}
            
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "#8C92AC",
                width: "127px",
                height: "48px",
                borderRadius: "24px",
                fontWeight: 500,
                "&:hover": {
                  background: "#8C92AC",
                },
              }}
              onClick={() => {
                setLoader(true)
                logout();
                close();
                navigate("/");
                setLoader(false)
              }}
            >
                   {loader ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      ":hover": { color: "#fff" },

                      color: "#fff",
                    }}
                  />
                ) : (
           hallLabelsName
              ? hallLabelsName.filter((e) => e.key == "lbl_logout_web")
                  .length > 0
                ? hallLabelsName.filter(
                    (e) => e.key == "lbl_logout_web"
                  )[0]["value"] || "lbl_logout_web"
                : "lbl_logout_web"
              : "lbl_logout_web")}
            </Button>
            
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  label: state.language,

});

export default connect(mapStateToProps, { logout })(LogoutModal);
