import React, { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "../../ui-component/extended/AppBar";
import FooterPage from "views/pages/landing/Footer";

import Customization from "../Customization";
// import navigation from "menu-items";
import useConfig from "hooks/useConfig";

import { useDispatch, useSelector } from "store";

// assets
import { IconChevronRight } from "@tabler/icons";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    // marginTop: "69px",
    borderRadius: "0px",
    padding: "0px",
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();

  const { drawerOpen } = useSelector((state) => state.menu);
  const { container } = useConfig();

  const header = useMemo(
    () => (
      <Toolbar>
        <Header />
      </Toolbar>
    ),
    []
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0}>
        {header}
      </AppBar>

      {/* main content */}
      <Main
        theme={theme}
        sx={{
          bgcolor: "#FFFFFF",
          marginX: 0,
          marginTop: { xs: "69px", sm: "97px", md: "69px" },
        }}
      >
        {/* breadcrumb */}
        {container && (
          <Container maxWidth="lg">
            <Breadcrumbs
              separator={IconChevronRight}
              //   navigation={navigation}
              icon
              title
              rightAlign
            />
            <Outlet />
          </Container>
        )}
        {!container && (
          <>
            <Breadcrumbs
              separator={IconChevronRight}
              //   navigation={navigation}
              icon
              title
              rightAlign
            />
            <Outlet />
          </>
        )}
        <FooterPage />
      </Main>

      <Customization />
    </Box>
  );
};

export default MainLayout;
