import axios from "axios";
import { GET_LANGUAGES,GET_LABELS_Name } from "../actions";
const PROXY = process.env.REACT_APP_URL + "api/";



export const getLanguages = () => async (dispatch) => {
  const res = await axios.get(PROXY + "user/label-language/get-language");
  dispatch({
    type: GET_LANGUAGES,
    payload: res.data.result,
  });
  return res;
}

export const getLabelVersion = () => async (dispatch) => {
  const res = await axios.get(PROXY + "setting");
  if(localStorage.getItem('hall-label-version')){
    if(localStorage.getItem('hall-label-version' ) != res.data.result.label_version){
    
      localStorage.setItem('hall-label-version' , res.data.result.label_version)
      dispatch(getLabelsName())
    }
  }
  else{
    localStorage.setItem('hall-label-version' , res.data.result.label_version)
  dispatch(getLabelsName())
  }
return res.data.result.label_version
}

export const getLabelsName =
  (language = 'ar', type = 0) =>
  async (dispatch) => {
    language = localStorage.getItem('hall-language-setting') || 'ar'

    const res = await axios.get(
      `${PROXY}user/label-language/get-label?language=${language}&type=${type}`
    )



    if (res?.data?.result?.length > 0) {
      dispatch({
        type: GET_LABELS_Name,
        payload: res.data.result,
      })
    }
  }
