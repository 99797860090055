import React from "react";

// material-ui
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const CardSkeleton = () => {
  return (
    <>
      <Card sx={{ display: "flex" }}>
        <CardMedia>
          <Skeleton
            animation="false"
            variant="rectangular"
            sx={{ width: "150px", height: "100%" }}
          />
        </CardMedia>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <CardContent
            sx={{
              flex: "1 0 auto",
              px: "1rem !important",
              pb: "0 !important",
              p: 0,
            }}
          >
            <Skeleton
              animation="false"
              variant="text"
              height={30}
              sx={{ mt: 0, my: 0.5 }}
            />
            <Skeleton
              animation="false"
              variant="text"
              height={30}
              sx={{ my: 0.5 }}
            />
            <Skeleton
              animation="false"
              variant="text"
              height={30}
              sx={{ my: 0.5 }}
            />
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default CardSkeleton;

export const CardDetailImageSkeleton = () => {
  return (
    <>
      <Card sx={{ display: "block" }}>
        <CardMedia>
          <Skeleton
            animation="true"
            variant="rectangular"
            sx={{ width: "100%", height: "335px" }}
          />
        </CardMedia>
      </Card>
    </>
  );
};
export const CardImageSkeleton = () => {
  return (
    <>
      <Card sx={{ display: "block" }}>
        <CardMedia>
          <Skeleton
            animation="true"
            variant="rectangular"
            sx={{ width: "100%", height: "205px" }}
          />
        </CardMedia>
      </Card>
    </>
  );
};

export const CardDetailTextSkeleton = () => {
  return (
    <>
      <Card sx={{ display: "flex" }}>
        <Box
          sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}
        >
          <CardContent
            sx={{
              flex: "1 0 auto",
              px: "1rem !important",
              pb: "0 !important",
              p: 0,
            }}
          >
            <Skeleton animation="false" variant="text" height={30} />
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export const RatingSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <CardContent
        sx={{
          flex: "1 0 auto",
          px: "1rem !important",
          pb: "0 !important",
          p: 0,
        }}
      >
        <Skeleton
          animation="false"
          variant="text"
          height={30}
          sx={{ mt: 0, my: 0.5 }}
        />
        <Skeleton
          animation="false"
          variant="text"
          height={30}
          sx={{ my: 0.5 }}
        />
        <Skeleton
          animation="false"
          variant="text"
          height={30}
          sx={{ my: 0.5 }}
        />
      </CardContent>
    </Box>
  );
};
export const PrivacyPageSkeleton=()=>{
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
  <Skeleton
  animation="false"
  variant="text"
  height={30}
  sx={{ mt: 0, my: 0.5 }}
/>
<Skeleton
  animation="false"
  variant="text"
  height={30}
  sx={{ my: 0.5 }}
/>
<Skeleton
  animation="false"
  variant="text"
  height={30}
  sx={{ my: 0.5 }}
/>
        <Skeleton
        animation="false"
        variant="text"
        height={30}
        sx={{ mt: 0, my: 0.5 }}
      />
      <Skeleton
        animation="false"
        variant="text"
        height={30}
        sx={{ my: 0.5 }}
      />

      </Box>
  )
}
export const RatingsDetailsCard = () => (
  <Card sx={{ p: 2 }}>
    <List sx={{ py: 0 }}>
      <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
        <ListItemAvatar>
          <Skeleton variant="circle" width={44} height={44} />
        </ListItemAvatar>
        <ListItemText
          sx={{ py: 0 }}
          primary={<Skeleton variant="rectangular" height={20} />}
          secondary={<Skeleton variant="text" />}
        />
      </ListItem>
      <ListItemText
        sx={{ py: 0 }}
        primary={<Skeleton variant="rectangular" height={20} />}
        secondary={<Skeleton variant="text" />}
      />
    </List>
  </Card>
);
