import axios from "axios";
import { MY_COURSE_LIST, MY_COURSE_DETAILS } from "../actions";

const PROXY = process.env.REACT_APP_URL + "api/";

export const getMyCourseList =
  (page, limit, search, categoryId) => async (dispatch) => {
    try {
      const res = await axios.get(
        PROXY +
          `user/my-course?page=${page}&limit=${limit}&search=${search}&category=${categoryId}`
      );

      dispatch({
        type: MY_COURSE_LIST,
        payload: res.data.result,
      });
      return res;
    } catch (error) {}
  };

export const uploadCourse = (formData) => async (dispatch) => {
  return await axios.post(PROXY + "user/course/add-course", formData);
};
export const saveCourseAsDraft = (formData) => async (dispatch) => {
  return await axios.post(PROXY + "user/course/add-save-as-draft", formData);
};

export const updateMyCourse = (id, formData) => async (dispatch) => {
  return await axios.put(PROXY + `user/course/edit-course/${id}`, formData);
};
export const updateMyDraftCourse = (id, formData) => async (dispatch) => {
  return await axios.put(PROXY + `user/course/edit-save-as-draft/${id}`, formData);
};
export const deleteMyDraftCourse = (id,data) => async (dispatch) => {
  return await axios.put(PROXY + `user/course/delete-course-before-publish/${id}`,data);
};