import React, {useEffect} from "react"
import { useTheme, styled } from "@mui/material/styles";
import { Container, Grid,  Typography,Box } from "@mui/material";
import {   Events,scrollSpy ,Link,scroller } from 'react-scroll';
// project imports
import {Link as Link1} from "react-router-dom"
import { gridSpacing } from "store/constant";

import VisaImage from "assets/images/payment/Visalogo.webp"
import MasterImage from "assets/images/payment/master-card.svg"
import PayTabImage from "assets/images/payment/logo-paytabs.jpg"
import MadaImage from "assets/images/payment/mada.svg"
import { connect,useSelector  } from "react-redux";
// import logoDark from 'assets/images/logo-white.svg';
import Logo from "ui-component/Logo";
import { useNavigate } from 'react-router-dom';
// styles




// ==============================|| LANDING - FOOTER PAGE ||============================== //

const FooterPage = (props) => {
      let activeLanguage=localStorage.getItem('hall-language-setting') ? localStorage.getItem('hall-language-setting') :"ar"
  const theme = useTheme();
  const navigate = useNavigate();
  const { hallLabelsName } = props.label;
  const auth = useSelector((state) => state.auth);
  const { myCourseList } = useSelector((state) => state.myCourse);
  const { enrollmentCourseList} = useSelector((state) => state.enrollment);
  const [showLoggedHeader, setShowLoggedHeader] = React.useState();
  const { isAuthenticated, loading,  } = auth;
  const logged = !loading && isAuthenticated;

  const FooterWrapper = styled("div")(({ theme }) => ({
    padding: "30px 0",
    minHeight: "180px",
    color: "#252525",
    background: "#8C92AC",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));
  
  const FooterLink = styled(Link)({
    color: "#252525",
    paddingBottom: "8px",
    fontWeight: 500,
    textDecoration: "none !important",
    cursor:"pointer",
    opacity: "1",
    textAlign: activeLanguage=="en" ? "left":"right",
    "& svg": {
      fontsize: "1.125rem",
      marginRight: 8,
    },
    "&:hover": {
      opacity: "1",
    },
  });
  
  const FooterSubWrapper = styled("div")(({ theme }) => ({
    padding: "0px 0",
    color: "#fff",
    background: "#7F85A2",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));

  useEffect(() => {
    if (logged) {
      setShowLoggedHeader(true);
    }
    else {
      setShowLoggedHeader(false);
    }
  },[])

  
  useEffect(() => {
    
    // Registering the 'begin' event and logging it to the console when triggered.
    

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register('end', (to, element) => {
      const newPosition = window.scrollY - 50; 
      window.scrollTo({
        top: newPosition,
        behavior: 'smooth',
      });
      
    });

    // Updating scrollSpy when the component mounts.
   scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      
    
      Events.scrollEvent.remove('end');
      
    };
  }, []);

  const handleScrollToSection = (route) => {
    // Navigate to home page
    navigate('/');

    setTimeout(()=>{
    scroller.scrollTo(route, {
      duration: 0,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
    },1000)

  };

  const handleScrollToHome=()=>{
    navigate('/');
    window.scroll(0,0)
  }

  
  return (
    <>
      <FooterWrapper>
        <Container>
          <Grid container alignItems="center" spacing={gridSpacing}>
            <Grid item xs={12} sm={3} sx={{display:"flex",flexDirection:"column",alignItems: activeLanguage=="en" ? "flex-start":"flex-end"}} >
              <Box sx={{ display: "flex", alignItems: "center",      }}>
                <Logo />
                <Typography sx={{ fontSize: "24px", pl: 1.5,color:"#424242",fontFamily:'Times New Roman, Regular !important'  }}>
                { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_the_hall_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_the_hall_web"
              )[0]["value"] || "lbl_the_hall_web"
            : "lbl_the_hall_web"
          : "lbl_the_hall_web"}
                </Typography>
              </Box>
              <Grid item sx={{ pt: 2 }}>
                <Typography
                  sx={{ color: "#252525", fontSize: "12px", fontWeight: 500  ,    textAlign: activeLanguage=="en" ? "left":"right" }}
                >
                {/* { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_welcome_to_our_dynamic_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_welcome_to_our_dynamic_web"
              )[0]["value"] || "lbl_welcome_to_our_dynamic_web"
            : "lbl_welcome_to_our_dynamic_web"
          : "lbl_welcome_to_our_dynamic_web"}  */}
                     { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_unlock_your_potential_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_unlock_your_potential_web"
              )[0]["value"] || "lbl_unlock_your_potential_web"
            : "lbl_unlock_your_potential_web"
          : "lbl_unlock_your_potential_web"}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", pt: 1, fontWeight: 500,textAlign: activeLanguage=="en" ? "left":"right" }}
                >
                  
                  { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_experience_a_transformative_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_experience_a_transformative_web"
              )[0]["value"] || "lbl_experience_a_transformative_web"
            : "lbl_experience_a_transformative_web"
          : "lbl_experience_a_transformative_web"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid
                container
                spacing={{ xs: 2, sm: 7 }}
                sx={{
                  justifyContent: "flex-end",
                  [theme.breakpoints.down("md")]: { justifyContent: "center" },
                }}
              >
                <Grid item sx={{ display: "flex", flexDirection: "column",textAlign:"left" }}>
                  <FooterLink
                    target="_blank"
                    underline="hover"
                    style={{ fontSize: "16px", fontWeight: 550 }}
                    onClick={()=>handleScrollToHome()}
                  >
             
                  { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_home_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_home_web"
              )[0]["value"] || "lbl_home_web"
            : "lbl_home_web"
          : "lbl_home_web"}
 
                  </FooterLink>
                  <FooterLink  underline="hover"    >
                  {/* onClick={()=>handleScrollToSection("category")} */}
                   <Link1 to="/select-category"  style={{textDecoration:"none",color:"inherit"}}>  
                               
                  { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_categories_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_categories_web"
              )[0]["value"] || "lbl_categories_web"
            : "lbl_categories_web"
          : "lbl_categories_web"}
        
               </Link1>  
              
                  </FooterLink>
                  <FooterLink underline="hover" >
                  {/* onClick={()=>handleScrollToSection("topCourses")} */}
                <Link1 to="/all-courses" style={{textDecoration:"none",color:"inherit"}}> 
                 
                    { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_top_courses_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_top_courses_web"
              )[0]["value"] || "lbl_top_courses_web"
            : "lbl_top_courses_web"
          : "lbl_top_courses_web"}
     </Link1> 
                  </FooterLink>
                  <FooterLink underline="hover" onClick={()=>handleScrollToSection("topInstructors")}>
                    {/* <Link to="topInstructors"> */}
                    { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_top_instructors_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_top_instructors_web"
              )[0]["value"] || "lbl_top_instructors_web"
            : "lbl_top_instructors_web"
          : "lbl_top_instructors_web"}
          {/* </Link> */}
          </FooterLink>
                  <FooterLink underline="hover" onClick={()=>handleScrollToSection("recentCourses")}>
                    {/* <Link to="recentCourses"> */}
                  { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_recent_courses_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_recent_courses_web"
              )[0]["value"] || "lbl_recent_courses_web"
            : "lbl_recent_courses_web"
          : "lbl_recent_courses_web"}
          {/* </Link> */}
                  </FooterLink>
                </Grid>
                <Grid item sx={{ display: "flex", flexDirection: "column",textAlign:"left" }}>
                  <FooterLink
                    target="_blank"
                    underline="hover"
                    style={{ fontSize: "16px", fontWeight: 550 }}
                  >
                    <Link1 to={showLoggedHeader ? myCourseList?.docs?.length>0 ? "/my-course": "/public-course" : "/login"} style={{color:"inherit",textDecoration:"none"}}>
                    { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_my_courses_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_my_courses_web"
              )[0]["value"] || "lbl_my_courses_web"
            : "lbl_my_courses_web"
          : "lbl_my_courses_web"}
          </Link1>
                  </FooterLink>
                  <FooterLink
                    underline="hover"
                    
                    component={Link}
                  >
                         <Link1 to={showLoggedHeader ?  "/public-course" : "/login"} style={{color:"inherit",textDecoration:"none"}}>
                    { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_publish_course_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_publish_course_web"
              )[0]["value"] || "lbl_publish_course_web"
            : "lbl_publish_course_web"
          : "lbl_publish_course_web"}
                    </Link1>
                  </FooterLink>
                </Grid>
                <Grid item sx={{ display: "flex", flexDirection: "column",textAlign:"left" }}>
                  <FooterLink
                    target="_blank"
                    underline="hover"
                    style={{ fontSize: "16px", fontWeight: 550 }}
                  >
                    <Link1         to={showLoggedHeader ? enrollmentCourseList?.docs?.length>0 ? "/enrolments": "/explore-course" : "/login"} style={{color:"inherit",textDecoration:"none"}}>
                    { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_enrolments_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_enrolments_web"
              )[0]["value"] || "lbl_enrolments_web"
            : "lbl_enrolments_web"
          : "lbl_enrolments_web"}
          </Link1>
                  </FooterLink>
                  <FooterLink
                    component={Link}
                    underline="hover"
                    
                  >
                    <Link1 to="/explore-course" style={{color:"inherit",textDecoration:"none"}}>
                    
                    { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_explore_course_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_explore_course_web"
              )[0]["value"] || "lbl_explore_course_web"
            : "lbl_explore_course_web"
          : "lbl_explore_course_web"}
          </Link1>
                  </FooterLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
         
        </Container>
     
      </FooterWrapper>
      <FooterSubWrapper>
        <Container sx={{display:"flex",justifyContent:"space-between",flexDirection:{xs:"column",sm:"row"}}}>
          <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:activeLanguage=="en" ? "flex-start":"flex-end" }}>
          <Typography
            sx={{
              color: "#252525",
              fontSize: "14px",
              paddingTop:{xs:"10px",sm:"0px"},
              fontWeight: 500,
              display:"flex",
              alignItems:"center",
         
            }}
          >
            
            © 2023 { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_the_hall_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_the_hall_web"
              )[0]["value"] || "lbl_the_hall_web"
            : "lbl_the_hall_web"
          : "lbl_the_hall_web"}. { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_all_rights_reserved_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_all_rights_reserved_web"
              )[0]["value"] || "lbl_all_rights_reserved_web"
            : "lbl_all_rights_reserved_web"
          : "lbl_all_rights_reserved_web"} 
          </Typography>
          <Box sx={{display:"flex",alignItems:"center",    }}>
            <Link1 to="/privacy-policy" style={{textDecoration:"none"}}>
            <Typography sx={{color: "#252525",
              fontSize: "14px",fontWeight:500,cursor:"pointer"}}>
            { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_privacy_policy_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_privacy_policy_web"
              )[0]["value"] || "lbl_privacy_policy_web"
            : "lbl_privacy_policy_web"
          : "lbl_privacy_policy_web"}
            </Typography>
            </Link1>
            <Typography sx={{paddingX:"5px",    color: "#252525",
              fontSize: "14px",fontWeight:500}}>
|
</Typography>
<Link1 to="/terms-and-condition" style={{textDecoration:"none"}}>
<Typography sx={{  color: "#252525",
              fontSize: "14px",fontWeight:500,cursor:"pointer"}}>
{ hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_terms_and_conditions_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_terms_and_conditions_web"
              )[0]["value"] || "lbl_terms_and_conditions_web"
            : "lbl_terms_and_conditions_web"
          : "lbl_terms_and_conditions_web"}
</Typography>
</Link1>
          </Box>
          </Box>
          <Box sx={{display:"flex",alignItems:"center"}} style={{marginLeft:"0px"}}>
          <Box sx={{ width: "100px",
                  height: "40px",borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"center",marginTop:"0rem",marginRight:"0.25rem",   backgroundColor:"white"}}>
            <img
                src={VisaImage}
                alt="stripe-visa-card-logo"
                style={{
                  width: "80px",
                  height: "26px",
                }}
              />
                     </Box>
              <img
                src={MasterImage}
                alt="stripe-master-card-logo"
                style={{
                  width: "100px",
                  height: "40px",
                  margin: "0.5rem 0.25rem 0.5rem 0",
                  backgroundColor:"white",
                  borderRadius:"5px"
                }}
              />  
                  <Box sx={{ width: "100px",
                  height: "40px",borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"center",marginTop:"0rem",marginRight:"0.25rem",   backgroundColor:"white"}}>
                  <img
                src={PayTabImage}
                alt="stripe-visa-card-logo"
                style={{
                  width: "80px",
                  height: "26px",
                 
                }}
              />
              </Box>
              <Box sx={{ width: "100px",
                  height: "40px",borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"center",marginTop:"0rem",backgroundColor:"white"}}>
              <img
                src={MadaImage}
                alt="stripe-master-card-logo"
                style={{
                  width: "80px",
                  height: "30px",
                  backgroundColor:"white"
                }}
              />  
              </Box>
            </Box>
        </Container>
      </FooterSubWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  label: state.language,

});

export default connect(mapStateToProps,{})(FooterPage);
