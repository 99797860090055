import axios from "axios";
import { ALL_NOTIFICATION,  } from "../actions";
const PROXY = process.env.REACT_APP_URL+ "api/";

// Load User
export const getNotification = (page, limit,language ) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${PROXY}user/notification/list-notification?page=${page}&limit=${limit}&language=${language}`
    );
    dispatch({
      type: ALL_NOTIFICATION,
      payload: res.data.result,
    });
    return res.data.result;
  } catch (err) {
    console.error(err);
  }
};

export const UpdateNotificationCount = () => async (dispatch) => {
  const res = await axios.put(
    `${PROXY}user/notification/update-unreadCount`
  );
  return res;
};