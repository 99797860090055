// action - state management
import { INSTRUCTOR_DETAILS, INSTRUCTOR_COURSE_LIST } from "./actions";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  instructorCourseList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  instructorDetails: {},
  loading: true,
};

export default function instructorReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case INSTRUCTOR_DETAILS:
      return {
        ...state,

        instructorDetails: payload,
        loading: false,
      };
    case INSTRUCTOR_COURSE_LIST:
      return {
        ...state,

        instructorCourseList: payload,
        loading: false,
      };
    default:
      return state;
  }
}
