import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_FAIL,
  ADMIN_LOADED,
  AUTH_FAIL,
} from "../actions";
import { setAlert } from "./alertAction";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import setAuthToken from "Helpers/setAuthToken";
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const PROXY = process.env.REACT_APP_URL + "api/";

export const login = (userObj) => async (dispatch) => {
  try {
    const body = JSON.stringify(userObj);
    const res = await axios.post(PROXY + "login", body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    return res;
  } catch (error) {

    dispatch({
      type: LOGIN_FAIL,
    });
    if (error.response) {
      dispatch(setAlert(error.response.data.message, "danger"));
    } else {
      dispatch(setAlert("Error occurred, Please try again later", "danger"));
    }
    return error.response;
  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.HallWebToken) {
    setAuthToken(localStorage.HallWebToken);
  }

  try {
    const res = await axios.get(PROXY + "user/auth");

    dispatch({
      type: ADMIN_LOADED,
      payload: res.data.user,
    });
    return res;
  } catch (error) {
    dispatch({
      type: AUTH_FAIL,
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const changePassword = (objPassword) => async (dispatch) => {
  const body = JSON.stringify(objPassword);

  const res = await axios.put(PROXY + "admin/change-password", body, config);
  dispatch(openSnackbar(res.data.message, "success"));
  return res;
};

export const updateProfile = (formData) => async (dispatch) => {
  return await axios.put(PROXY + "user/update_profile", formData);
};
export const signUp = (formData) => async (dispatch) => {
  return await axios.post(PROXY + "register", formData);
};
export const sendOtpForSignUp = (data) => async (dispatch) => {
  return await axios.post(PROXY + "send_email_otp", data);
};
export const verifyEmailForSignUp = (data) => async (dispatch) => {
  return await axios.post(PROXY + "verifycode", data);
};

export const forgotPassword = (objPassword) => async (dispatch) => {
  const body = JSON.stringify(objPassword);

  const res = await axios.post(PROXY + "forgot_password", body, config);

  return res;
};
