import {  GET_LABELS_Name } from "./actions";

const initialState = {
    hallLabelsName: [],
  
  
    loading: true,
  };
  
  export default function languageReducer(state= initialState , action) {
    const { type, payload } = action;
    switch (type) {
 
      case GET_LABELS_Name:
        return { ...state, hallLabelsName: payload };
      default:
        return state;
    }
  }