import React, { useEffect } from "react";
// routing
import Routes from "routes";

// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";
import ThemeCustomization from "themes";
import { store, persister } from "store";
import { PersistGate } from "redux-persist/integration/react";
// auth provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";
import { loadUser } from "store/slices/userLoginAction";
import { getLabelsName,getLabelVersion } from './store/slices/languageLabelAction'
// ==============================|| APP ||============================== //


const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
      store.dispatch(getLabelVersion());
 
    
    
  
  }, []);

  return (
    <ThemeCustomization store={store}>
      <PersistGate loading={null} persistor={persister}>
        {/* RTL layout */}
        <RTLLayout>
          <Locales>
            {/* <NavigationScroll> */}
              <AuthProvider>
                <>
                  <Routes />
                  <Snackbar />
                </>
              </AuthProvider>
            {/* </NavigationScroll> */}
          </Locales>
        </RTLLayout>
      </PersistGate>
    </ThemeCustomization>
  );
};

export default App;
