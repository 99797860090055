
import { useTheme, styled } from "@mui/material/styles";
import { Divider, Grid, List, Stack, Typography } from "@mui/material";
import {PrivacyPageSkeleton} from "ui-component/skeleton/skeleton";
import {  useSelector } from "react-redux";
import ClockIcon from "assets/images/Profile/time-clock-svgrepo-com.svg";
import CalendarIcon from "assets/images/Profile/8665078_calendar_days_icon.svg";
import moment from "moment";
import "moment/locale/en-in";
import 'moment/locale/ar';
// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 16,
  paddingTop:8,

  "&:hover": {
    background: "#F4F4F7",
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({data,count,loading}) => {
  const {hallLabelsName}=useSelector((state)=>state.language)
 
    let language=localStorage.getItem('hall-language-setting') ? localStorage.getItem('hall-language-setting'):"ar"

 
 

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 377,
        py: 0,
        borderRadius: "10px",

        "& .MuiListItemSecondaryAction-root": {
          top: 22,
        },
        "& .MuiDivider-root": {
          my: 0,
        },
      }}
    >
      { !loading && data?.length >0 && data.map((res,index)=>
      <ListItemWrapper style={{backgroundColor:index<count?"#F4F4F7":""}}>
        <Grid container direction="column" className="list-container" sx={{alignContent: language=="en" ? "flex-start":"flex-end"}}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "#252525",
                fontWeight: 500,
                fontSize: "14px",
                textAlign: "left",
                lineHeight:1,
                wordBreak:"break-all"
              }}
            >
             {res.description}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 0.5 }}>
            <Stack direction="row" sx={{flexDirection: language == "en" ? "row" : "row-reverse",}}>
              <img src={CalendarIcon} alt="" />
              <Typography
                sx={{
                  color: "#909090",
                  fontSize: "12px",
                  fontWeight: 500,
                  pl:language == "en" ? 1 :2,
                  pr:language == "en" ? 2:1,
                }}
              >
              { moment(res.createdAt).locale(language=="en"?'en-IN':"ar").format("Do MMMM YYYY")}
              </Typography>
              <img src={ClockIcon} alt="" />
              <Typography
                sx={{
                  color: "#909090",
                  fontSize: "12px",
                  fontWeight: 500,
                  pl: 1,
                  pr:1
                }}
              >
                {
              
               `${res.timeSince} ${res.timeSinceString} ${ hallLabelsName
        ? hallLabelsName.filter((e) => e.key == "lbl_ago_web")
            .length > 0
          ? hallLabelsName.filter(
              (e) => e.key == "lbl_ago_web"
            )[0]["value"] || "lbl_ago_web"
          : "lbl_ago_web"
        : "lbl_ago_web"}`  }
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </ListItemWrapper>
      )}
      {!loading && data?.length ==0 &&
      <Typography sx={{    color: "#8C92AC",
      fontWeight: 500,
      fontSize: "16px",paddingLeft:"16px",paddingBottom:"20px", textAlign: language=="en" ? "left":"right"}}>{ hallLabelsName
        ? hallLabelsName.filter((e) => e.key == "lbl_no_notification_found_web")
            .length > 0
          ? hallLabelsName.filter(
              (e) => e.key == "lbl_no_notification_found_web"
            )[0]["value"] || "lbl_no_notification_found_web"
          : "lbl_no_notification_found_web"
        : "lbl_no_notification_found_web"}</Typography>}
        {loading && <PrivacyPageSkeleton />}
      <Divider />
    
    </List>
  );
};

export default NotificationList;
