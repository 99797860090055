import { ALL_NOTIFICATION } from "./actions";

const initialState = {
  notificationList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },

  loading: true,
};

const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ALL_NOTIFICATION:
      return {
        ...state,
        notificationList: payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default notificationReducer;