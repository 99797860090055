import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {  useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useScrollTrigger,
  Grid,
  Switch,
} from "@mui/material";

// project imports
import Logo from "ui-component/Logo";
import i18next from 'ui-component/i18next'
// assets
import {
  IconBook,
  IconHome2,
  IconQuestionMark,
  IconShieldLock,
  
  IconUser,
} from "@tabler/icons";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import { getMyCourseList } from "store/slices/myCourseAction";
import { getLabelsName } from 'store/slices/languageLabelAction'
import NotificationSection from "layout/MainLayout/NotificationSection";
import {getEnrollmentCourseList} from "store/slices/enrollmentAction";
import LogOutIcon from "assets/images/Profile/logout-svgrepo-com.svg";
import LogoutModal from "../../views/pages/Settings/LogoutModal";
import LogoutIcon from '@mui/icons-material/Logout';

function ElevationScroll({ children, window }) {
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });
  const darkBorder =
    theme.palette.mode === "dark"
      ? theme.palette.dark.dark
      : theme.palette.grey[200];

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    style: {
      backgroundColor: theme.palette.background.default,
      borderBottom: trigger ? "none" : "1px solid",
      borderColor: trigger ? "" : darkBorder,
      color: theme.palette.text.dark,
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.node,
  window: PropTypes.object,
};

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const AppBar = ({getMyCourseList,getLabelsName,label,getEnrollmentCourseList, ...others }) => {
  const theme = useTheme();
  const { id ,selectedpage} = useParams();
  const auth = useSelector((state) => state.auth);
  const { hallLabelsName } = label;
  const { myCourseList } = useSelector((state) => state.myCourse);
  const { enrollmentCourseList} = useSelector((state) => state.enrollment);
  const { isAuthenticated, loading, admin } = auth;
  const [drawerToggle, setDrawerToggle] = React.useState(false);
  const [showLoggedHeader, setShowLoggedHeader] = React.useState();
  const [activeTab, setActiveTab] = React.useState(0);
  const [showLogoutModal, setShowLogoutModal] = React.useState(false);
  const[language,setLanguage]=React.useState(localStorage.getItem('hall-language-setting') ? localStorage.getItem('hall-language-setting'):"ar")
  /** Method called on multiple components with different event types */
  const drawerToggler = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerToggle(open);
  };

  const logged = !loading && isAuthenticated;

  useEffect(()=>{
if(!hallLabelsName || hallLabelsName?.length==0){
  getLabelsName()
}
  },[hallLabelsName])



  useEffect(() => {
    if (logged) {
      setShowLoggedHeader(true);
      getMyCourseList(
        1,
        10,
        "",
       ""
      )
      getEnrollmentCourseList(
        1,
        10,
        "",
       ""
      )
    } else {
      setShowLoggedHeader(false);
    }
  }, [logged]);


  const ChangeActiveTab = (value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    if (window.location.pathname == "/public-course" || window.location.hash == "#/public-course") {
      setActiveTab(1);
    }else if( window.location.pathname=="/my-course" || window.location.hash == "#/my-course"){
      ChangeActiveTab(1)
      setActiveTab(1);
    }else if( window.location.pathname==`/my-course/${selectedpage}` || window.location.hash == `#/my-course/${selectedpage}`){
      ChangeActiveTab(1)
      setActiveTab(1);
    }
    else if( window.location.pathname==`/my-course-details/${id}` || window.location.hash == `#/my-course-details/${id}`){
      ChangeActiveTab(1)
      setActiveTab(1);
    }
    else if( window.location.pathname==`/my-course-details/${id}/${selectedpage}` || window.location.hash == `#/my-course-details/${id}/${selectedpage}`){
      ChangeActiveTab(1)
      setActiveTab(1);
    }
    else if( window.location.pathname==`/my-draft-course-details/${id}/${selectedpage}` || window.location.hash == `#/my-draft-course-details/${id}/${selectedpage}`){
      ChangeActiveTab(1)
      setActiveTab(1);
    }
    else if( window.location.pathname==`/my-course-ratings/${id}` || window.location.hash == `#/my-course-ratings/${id}`){
      ChangeActiveTab(1)
      setActiveTab(1);
    }
    else if(window.location.pathname=="/enrolments" || window.location.hash == "#/enrolments"){
      ChangeActiveTab(2)
      setActiveTab(2);
    }
    else if(window.location.pathname==`/enrolment-details/${id}` || window.location.hash == `#/enrolment-details/${id}`){
      ChangeActiveTab(2)
      setActiveTab(2);
    }
    else if(window.location.pathname==`/enrolments/${selectedpage}` || window.location.hash == `#/enrolments/${selectedpage}`){
      ChangeActiveTab(2)
      setActiveTab(2);
    }
    else if(window.location.pathname==`/enrolment-details/${id}/${selectedpage}` || window.location.hash == `#/enrolment-details/${id}/${selectedpage}`){
      ChangeActiveTab(2)
      setActiveTab(2);
    }
    else if(window.location.pathname==`/enrolment-ratings/${id}` || window.location.hash == `#/enrolment-ratings/${id}`){
      ChangeActiveTab(2)
      setActiveTab(2);
    }
    else if(window.location.pathname=="/my-profile" || window.location.hash == "#/my-profile"){
      ChangeActiveTab(5)
      setActiveTab(5);
    }else{
      ChangeActiveTab(5)
      setActiveTab(5); 
    }
  }, []);

  const handleChange = (event) => {
  
  if(event.target.checked){
    setLanguage("ar")
    localStorage.setItem('hall-language-setting', "ar")

    i18next.changeLanguage("ar")
    getLabelsName()
    // window.location.reload(true)
  }else{
    setLanguage("en")
    localStorage.setItem('hall-language-setting', "en")

    i18next.changeLanguage("en")
    getLabelsName()


  
    // window.location.reload(true)
  }
  };

  return (
    <>
    <ElevationScroll {...others}>
      <MuiAppBar>
        <Grid item xs={12}>
          <Container
            style={{
              maxWidth: "100vw",
            }}
          >
            <Toolbar
              sx={{ paddingX: { sm: "0px", md: "9px" }, paddingY: "9px" }}
            >
              <Typography
                 component={RouterLink}
                sx={{ flexGrow: 0.03, textAlign: "left" }}
                to="/"
              >
                <Logo />
              </Typography>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                }}
              >
                <Box
                 component={RouterLink}
                  to="/"
                  sx={{
                    textDecoration: "none",
                    fontSize: "24px",
                    color: "#424242",
                    fontFamily:'Times New Roman, Regular !important' 
                  }}
                >
                  { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_the_hall_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_the_hall_web"
              )[0]["value"] || "lbl_the_hall_web"
            : "lbl_the_hall_web"
          : "lbl_the_hall_web"}
                  
                </Box>
              </Typography>
              <Stack
                direction="row"
                sx={{ display: { xs: "none", sm: "block" } }}
                spacing={{ md: 2, sm: 1 }}
              >
                <Button
                  onClick={() => {
                    setActiveTab(0);
                    ChangeActiveTab(0);
                  }}
                  sx={{
                    color: activeTab == 0 ? "#8C92AC" : "inherit",
                    fontWeight: 500,
                  }}
                  component={RouterLink}
                  to="/"
                >
                  { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_home_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_home_web"
              )[0]["value"] || "lbl_home_web"
            : "lbl_home_web"
          : "lbl_home_web"}
                </Button>
                <Button
                  onClick={() => {
                    setActiveTab(1);
                    ChangeActiveTab(1);
                  }}
                  sx={{
                    color:
                      showLoggedHeader && activeTab == 1
                        ? "#8C92AC"
                        : "inherit",
                    fontWeight: 500,
                  }}
                  component={RouterLink}
                  to={showLoggedHeader ? myCourseList?.docs?.length>0 ? "/my-course": "/public-course" : "/login"}
                >
                 
                 { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_my_courses_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_my_courses_web"
              )[0]["value"] || "lbl_my_courses_web"
            : "lbl_my_courses_web"
          : "lbl_my_courses_web"}
                </Button>
                <Button
                  onClick={() => {
                    setActiveTab(2);
                    ChangeActiveTab(2);
                  }}
                  sx={{
                    color:
                      showLoggedHeader && activeTab == 2
                        ? "#8C92AC"
                        : "inherit",
                    fontWeight: 500,
                  }}
                  component={RouterLink}
                  to={showLoggedHeader ? enrollmentCourseList?.docs?.length>0 ? "/enrolments": "/explore-course" : "/login"}
                >
                           { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_enrolments_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_enrolments_web"
              )[0]["value"] || "lbl_enrolments_web"
            : "lbl_enrolments_web"
          : "lbl_enrolments_web"}
                  
                </Button>

                <Grid
                  component={Link}
                  sx={{
                    color: "#252525",
                    textDecoration: "none",
                    fontWeight: 500,
                  }}
                >
                  English
                  <Switch
                    checked={language == "ar" ? true:false}
                    onChange={handleChange}
                    sx={{
                      mx: 1,
                      width: 46,
                      height: "20px",
                      padding: 0,
                      "&:active": {
                        "& .MuiSwitch-thumb": {
                          width: 14,
                        },
                      },
                      "& .MuiSwitch-switchBase": {
                        padding: "3px",
                        "&.Mui-checked": {
                          transform: "translateX(27px)",
                          color: `#FFFFFF !important`,
                        },
                      },
                      "& .MuiSwitch-thumb": {
                        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
                        width: 14,
                        height: 14,
                        transition: theme.transitions.create(["width"], {
                          duration: 200,
                        }),
                      },
                      "& .MuiSwitch-track": {
                        borderRadius: 16,
                        opacity: 1,
                        backgroundColor: `#8C92AC !important`,
                        boxSizing: "border-box",
                      },
                      "& .Mui-checked+.MuiSwitch-track": {
                        bgcolor: `#8C92AC !important`,
                        opacity: "1 !important",
                      },
                    }}
                  />
                  عربي
                </Grid>

                {showLoggedHeader ? (
                  <>
                    <Button component={Link}>
                      <NotificationSection />
                    </Button>
                    <Button
                      color="inherit"
                      component={RouterLink}
                      to="/my-profile"
                      style={{ marginLeft: "5px" }}
                      onClick={() => {
                        setActiveTab(5);
                        ChangeActiveTab(5);
                      }}
                    >
                      <img
                        src={
                          admin?.profile_pic
                            ? admin?.profile_pic
                            : `https://ui-avatars.com/api/?name=${
                                admin?.first_name + admin?.last_name
                              }&rounded=true&background=c39a56&color=fff&format=png`
                        }
                        alt=""
                        width="38px"
                        height="38px"
                        style={{ borderRadius: "50%" }}
                      />
                    </Button>
                    <Button style={{marginLeft:"0px",paddingLeft:"0px"}}  onClick={() => setShowLogoutModal(true)} >
       
       
          <img src={LogOutIcon} alt="" style={{height:"30px"}} />
      
      
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      //component={Link}
                      component={RouterLink}
                      to="/login"
                      disableElevation
                      variant="contained"
                      sx={{
                        borderRadius: "23px",
                        backgroundColor: "#8C92AC",
                        width: "110px",
                        minWidth:"110px",
                        lineHeight:1,
                        height: "38px",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#8C92AC",
                        },
                      }}
                    >
                        { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_login_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_login_web"
              )[0]["value"] || "lbl_login_web"
            : "lbl_login_web"
          : "lbl_login_web"}
                    </Button>
                    <Button
                      component={RouterLink}
                      //component={RouterLink}
                      to="/register"
                      disableElevation
                      variant="outlined"
                      sx={{
                        borderRadius: "25px",
                        border: "1px solid #9D9D9D",
                        width: "124px",
                        height: "38px",
                        color: "#9D9D9D",
                        paddingX: "0px",
                        "&:hover": {
                          border: "1px solid #9D9D9D",
                        },
                      }}
                    >
                         { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_create_account_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_create_account_web"
              )[0]["value"] || "lbl_create_account_web"
            : "lbl_create_account_web"
          : "lbl_create_account_web"}
                
                    </Button>
                  </>
                )}
              </Stack>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                {showLoggedHeader ? (
                  <Button>
                    <NotificationSection />
                  </Button>
                ) : null}
                <IconButton
                  color="inherit"
                  onClick={drawerToggler(true)}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="top"
                  open={drawerToggle}
                  onClose={drawerToggler(false)}
                >
                  {drawerToggle && (
                    <Box
                      sx={{ width: "auto" }}
                      role="presentation"
                      onClick={drawerToggler(false)}
                      onKeyDown={drawerToggler(false)}
                    >
                      <List>
                        <Link style={{ textDecoration: "none" }} to="/"  component={RouterLink}>
                          <ListItemButton component="a">
                            <ListItemIcon>
                              <IconHome2 />
                            </ListItemIcon>
                            <ListItemText primary=          { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_home_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_home_web"
              )[0]["value"] || "lbl_home_web"
            : "lbl_home_web"
          : "lbl_home_web"} />
                          </ListItemButton>
                        </Link>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={showLoggedHeader ? myCourseList?.docs?.length>0 ? "/my-course": "/public-course" : "/login"}
                          component={RouterLink}
                        >
                          <ListItemButton component="a">
                            <ListItemIcon>
                              <IconBook />
                            </ListItemIcon>
                            <ListItemText primary=    { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_my_courses_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_my_courses_web"
              )[0]["value"] || "lbl_my_courses_web"
            : "lbl_my_courses_web"
          : "lbl_my_courses_web"} />
                          </ListItemButton>
                        </Link>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={showLoggedHeader ? enrollmentCourseList?.docs?.length>0 ? "/enrolments": "/explore-course" : "/login"}
                          component={RouterLink}
                        >
                          <ListItemButton component="a">
                            <ListItemIcon>
                              <IconQuestionMark />
                            </ListItemIcon>
                            <ListItemText primary=                   { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_enrolments_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_enrolments_web"
              )[0]["value"] || "lbl_enrolments_web"
            : "lbl_enrolments_web"
          : "lbl_enrolments_web"} />
                          </ListItemButton>
                        </Link>


                        {showLoggedHeader ? (
                          <>
                            <Link
                              style={{ textDecoration: "none" }}
                              to="/my-profile"
                              component={RouterLink}
                            >
                              <ListItemButton component="a">
                                <ListItemIcon>
                                  <IconUser />
                                </ListItemIcon>
                                <ListItemText primary= { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_my_profile_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_my_profile_web"
              )[0]["value"] || "lbl_my_profile_web"
            : "lbl_my_profile_web"
          : "lbl_my_profile_web"}  />
                              </ListItemButton>
                            </Link>
                            <Link
                              style={{ textDecoration: "none" }}
                             
                              component={"div"}
                              onClick={() => setShowLogoutModal(true)}
                            >
                              <ListItemButton component="a">
                                <ListItemIcon>
                                  <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary= { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_logout_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_logout_web"
              )[0]["value"] || "lbl_logout_web"
            : "lbl_logout_web"
          : "lbl_logout_web"}  />
                              </ListItemButton>
                            </Link>
                          </>
                        ) : (
                          <Link
                            style={{ textDecoration: "none" }}
                            to="/login"
                            component={RouterLink}
                          >
                            <ListItemButton component="a">
                              <ListItemIcon>
                                <LoginIcon />
                              </ListItemIcon>
                              <ListItemText primary=               { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_login_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_login_web"
              )[0]["value"] || "lbl_login_web"
            : "lbl_login_web"
          : "lbl_login_web"} />
                            </ListItemButton>
                          </Link>
                        )}
                      </List>
                    </Box>
                  )} <List sx={{pt:0}}>
                         <Stack direction="row" sx={{paddingLeft:"16px"}}>
                            <IconShieldLock />
                         
                    <ListItemText sx={{paddingLeft:"13px"}}>
                            {" "}
                            English
                            <Switch
                              checked={language == "ar" ? true:false}
                              onChange={handleChange}
                              sx={{
                                mx: 1,
                                width: 46,
                                height: "20px",
                                padding: 0,
                                "&:active": {
                                  "& .MuiSwitch-thumb": {
                                    width: 14,
                                  },
                                },
                                "& .MuiSwitch-switchBase": {
                                  padding: "3px",
                                  "&.Mui-checked": {
                                    transform: "translateX(27px)",
                                    color: `#FFFFFF !important`,
                                  },
                                },
                                "& .MuiSwitch-thumb": {
                                  boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
                                  width: 14,
                                  height: 14,
                                  transition: theme.transitions.create(
                                    ["width"],
                                    {
                                      duration: 200,
                                    }
                                  ),
                                },
                                "& .MuiSwitch-track": {
                                  borderRadius: 16,
                                  opacity: 1,
                                  backgroundColor:
                                  `#8C92AC !important`,
                                  boxSizing: "border-box",
                                },
                                "& .Mui-checked+.MuiSwitch-track": {
                                  bgcolor: `#8C92AC !important`,
                                  opacity: "1 !important",
                                },
                              }}
                            />
                            عربي
                          </ListItemText>
                          </Stack>
                          </List>
                </Drawer>
              </Box>
            </Toolbar>
          </Container>
        </Grid>
      </MuiAppBar>
    </ElevationScroll>
    {showLogoutModal && (
      <LogoutModal
        open={showLogoutModal}
        close={() => setShowLogoutModal(false)}
      />
    )}
    </>
  );
};

const mapStateToProps = (state) => ({
  label: state.language,

});

export default connect(mapStateToProps,{getMyCourseList,getLabelsName,getEnrollmentCourseList})(AppBar);
