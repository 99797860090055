import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import PricingFront from "views/pages/pricing/Pricing";
import GuestGuard from "utils/route-guard/GuestGuard";
import NavMotion from "layout/NavMotion";

// login option 2 routing
const AuthLogin2 = Loadable(
  lazy(() => import("views/pages/authentication/authentication2/Login2"))
);
const AuthRegister2 = Loadable(
  lazy(() => import("views/pages/authentication/authentication2/Register2"))
);
const AuthForgotPassword2 = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication2/ForgotPassword2")
  )
);
const AuthVerifyEmail = Loadable(
  lazy(() => import("views/pages/authentication/authentication2/VerifyEmail"))
);
const VerifyEmailForSignUp = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication2/VerifyEmailSignUp")
  )
);
const AccountCreatedSuccessfully = Loadable(
  lazy(() =>
    import(
      "views/pages/authentication/authentication2/AccountCreatedSuccessfully"
    )
  )
);
const AuthCheckMail2 = Loadable(
  lazy(() => import("views/pages/authentication/authentication2/CheckMail2"))
);
const AuthResetPassword2 = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication2/ResetPassword2")
  )
);
const AuthCodeVerification2 = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication2/CodeVerification2")
  )
);

// login option 3 routing

// maintenance routing
const MaintenanceError = Loadable(
  lazy(() => import("views/pages/maintenance/Error"))
);

// landing & contact-us routing
const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));
const PagesContactUS = Loadable(lazy(() => import("views/pages/contact-us")));
const PagesFaqs = Loadable(lazy(() => import("views/pages/saas-pages/Faqs")));
const PagesPrivacyPolicy = Loadable(
  lazy(() => import("views/pages/saas-pages/PrivacyPolicy"))
);

// ============================== || AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: "/login",
      element: <AuthLogin2 />,
    },
    {
      path: "/register",
      element: <AuthRegister2 />,
    },
    {
      path: "/forgot-password",
      element: <AuthForgotPassword2 />,
    },
    {
      path: "/verify-email",
      element: <AuthVerifyEmail />,
    },
    {
      path: "/verify-email-for-signup",
      element: <VerifyEmailForSignUp />,
    },
    {
      path: "/account-created-successfully",
      element: <AccountCreatedSuccessfully />,
    },
    {
      path: "/check-mail",
      element: <AuthCheckMail2 />,
    },
    {
      path: "/reset-password",
      element: <AuthResetPassword2 />,
    },
    {
      path: "/code-verification",
      element: <AuthCodeVerification2 />,
    },

    {
      path: "/error",
      element: <MaintenanceError />,
    },

    {
      path: "/landing",
      element: <PagesLanding />,
    },
    {
      path: "/pricing",
      element: <PricingFront />,
    },
    {
      path: "/faqs",
      element: <PagesFaqs />,
    },
    // {
    //   path: "/privacy-policy",
    //   element: <PagesPrivacyPolicy />,
    // },
    {
      path: "/contact-us",
      element: <PagesContactUS />,
    },
  ],
};

export default AuthenticationRoutes;
