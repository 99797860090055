// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project imports
import snackbarReducer from "./slices/snackbar";
import contactReducer from "./slices/contact";
import chatReducer from "./slices/chat";
import mailReducer from "./slices/mail";
import cartReducer from "./slices/cart";
import menuReducer from "./slices/menu";
import accountReducer from "./accountReducer";
import dashboardReducer from "./dashboardReducer";
import instructorReducer from "./instructorReducer";
import myCourseReducer from "./myCourseReducer";
import cmsReducer from "./cmsReducer";
import notificationReducer from "./notificationReducer";
import languageReducer from "./languageReducer";
import creditReducer from "./creditReducer";
import enrollmentCourseReducer from "./enrollmentReducer";
import myProfileReducer from "./myProfileReducer"
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "berry-",
    },
    cartReducer
  ),
  contact: contactReducer,
  chat: chatReducer,
  mail: mailReducer,
  menu: menuReducer,
  auth: accountReducer,
  dashboard: dashboardReducer,
  instructor: instructorReducer,
  myCourse: myCourseReducer,
  cmsPages: cmsReducer,
  language: languageReducer,
  notification:notificationReducer,
  credit:creditReducer,
  enrollment:enrollmentCourseReducer,
  myProfile:myProfileReducer
});

export default reducer;
