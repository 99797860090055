import { lazy } from "react";
import UnRegisteredGuard from "utils/route-guard/UnRegisteredGuard";
import NavMotion from "layout/NavMotion";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const AllCourses = Loadable(
  lazy(() => import("views/pages/AllCourses/AllCourses"))
);
const SelectCategory = Loadable(
  lazy(() => import("views/pages/SelectCategory/SelectCategory"))
);
const InstructorSelectedCategory  = Loadable(
  lazy(() => import("views/pages/Instructor/selectCategory"))
);
const Instructor = Loadable(
  lazy(() => import("views/pages/Instructor/Instructor"))
);
const InstructorAllCourses=Loadable(lazy(()=>import("views/pages/Instructor/InstructorAllCourse")))
const InstructorCourseDetail=Loadable(lazy(()=>import("views/pages/Instructor/InstructorCourseDetails")))

const CourseDetail = Loadable(
  lazy(() => import("views/pages/CourseDetail/CourseDetail"))
);
const InstructorCourseRating=Loadable(lazy(()=>import("views/pages/Instructor/InstructorCourseRating")))
const ViewRatings = Loadable(lazy(() => import("views/pages/Ratings/Ratings")));
const TermsAndCondition = Loadable(
  lazy(() => import("views/pages/Settings/TermsAndCondition"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("views/pages/Settings/PrivacyPolicy"))
);

const GuestRoutes = {
  path: "/",
  element:(


      <MainLayout />


  ),
  children: [
    {
      path: "/all-courses",
      element: <AllCourses />,
    },
    {
      path: "/all-courses/:selectedpage",
      element: <AllCourses />,
    },
    {
      path: "/select-category",
      element: <SelectCategory />,
    },
    {
      path: "/instructor/:slug",
      element: <Instructor />,
    },
    {
      path:"/instructor-all-courses/:id/:selectedpage",
      element:<InstructorAllCourses/>
    },
    {
      path:"/instructor-course-details/:id/:instructorId/:selectedpage",
      element:<InstructorCourseDetail/>
    },
    {
      path: "/view-ratings/:id",
      element: <ViewRatings />,
    },
    {
      path:"/instructor-course-rating/:id/:instructorId",
      element:<InstructorCourseRating/>
    },
    {
      path: "/instructor-select-category/:id",
      element: <InstructorSelectedCategory />,
    },
    {
      path: "/course-details/:id",
      element: <CourseDetail />,
    },
    {
      path: "/course-details/:id/:selectedpage",
      element: <CourseDetail />,
    },
    {
      path: "/terms-and-condition",
      element: <TermsAndCondition />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
 
  ],
};
export default GuestRoutes;
