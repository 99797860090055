// action - state management
import {
    CREDIT_LIST,
  
  } from "./actions";
  
  // ==============================|| ACCOUNT REDUCER ||============================== //
  
  const initialState = {
    creditList: [],

    loading: true,
  };
  
  export default function creditReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREDIT_LIST:
        return {
          ...state,
  
          creditList: payload,
          loading: false,
        };

      default:
        return state;
    }
  }