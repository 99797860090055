import { useContext } from "react";

// auth provider
import AuthContext from "contexts/FirebaseContext";
import { useSelector } from "react-redux";
// import AuthContext from 'contexts/Auth0Context';
// import AuthContext from 'contexts/JWTContext';
// import AuthContext from 'contexts/AWSCognitoContext';

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, loading } = auth;

  return auth;
};

export default useAuth;
