// action - state management
import { MY_COURSE_LIST, SELECTED_MY_COURSE_CATEGORY } from "./actions";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  myCourseList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  selectedMyCourseCategories: [],
  myCourseDetails: {},
  loading: true,
};

export default function myCourseReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MY_COURSE_LIST:
      return {
        ...state,

        myCourseList: payload,
        loading: false,
      };
    case SELECTED_MY_COURSE_CATEGORY:
      return {
        ...state,

        selectedMyCourseCategories: payload,
        loading: false,
      };
    default:
      return state;
  }
}
